import styled, { css } from 'styled-components'

export const Block = styled.div<{
  disabled?: boolean
  readOnly?: boolean
}>`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`

export const Label = styled.label<{
  disabled?: boolean
  hasError?: boolean
}>`
  text-align: left;
  position: absolute;
  cursor: text;
  z-index: 2;
  letter-spacing: 0.4px;

  color: ${props => {
    if (props.disabled) return 'var(--color-sw-20)'
    if (props.hasError) return 'var(--error-color)'
    return '#717284'
  }};
  font: var(--typography-14-20-semibold);
  pointer-events: none;
  user-select: none;
  transform: scale(1) translate(0, 0);
  transform-origin: top left;
  transition: transform 0.2s ease;
  will-change: transform;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  right: 5px;
`

export const Input = styled.input<{
  hasError?: boolean
  hasLabel?: boolean
  $size?: 'M'
}>`
  font: var(--typography-14-16-semibold);

  width: 100%;
  height: 100%;
  appearance: none;
  box-sizing: border-box;
  border: none;
  outline: none;
  color: ${props => {
    if (props.hasError) return 'var(--error-color)'
    if (props.disabled) return 'var(--color-sw-20)'
    if (props.readOnly) return '#595A6F' // SNS-70
    return 'var(--color-night)'
  }};

  padding: ${props => {
    if (props.$size === 'M') return '11px'
    return props.hasLabel ? '23px 0 7px 0' : '15px 0'
  }};

  &:read-only ~ ${Label} {
    color: #595a6f;
  }

  &[disabled] ~ ${Label}, &:disabled ~ ${Label} {
    color: var(--color-sw-20);
  }

  :focus:not(:read-only) ~ ${Label} {
    color: var(--color-sw-60);
    transform: scale(0.7) translate(0, -7px);
    width: 100%;
  }

  :not(:placeholder-shown):not([value='']) ~ ${Label} {
    color: var(--color-sw-60);
    transform: scale(0.7) translate(0, -7px);
    width: 100%;
  }

  ${props =>
    props.hasLabel &&
    css`
      :not(:focus)::placeholder {
        opacity: 0;
      }
    `}
  :not([disabled]):not(:read-only):hover ~ ${Label} {
    color: var(--color-night);
  }

  :required {
    box-shadow: none;
  }

  transition: border 0.2s;
  background: transparent;

  input&:-internal-autofill-selected {
    background: #fff !important;
    box-shadow: inset 0 0 0 24px #fff;
  }

  ${props => (props.disabled || props.readOnly ? 'pointer-events: none;' : '')}
`

export const InputAfter = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  align-items: center;
  padding: 0 15px 0 0;
  height: 100%;
`

export const Wrapper = styled.div<{
  disabled?: boolean
  readOnly?: boolean
  $size?: 'M'
}>`
  font-family: var(--typography-normal);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  position: relative;

  --error-color: var(--color-red);

  :hover,
  :focus-within {
    box-shadow: ${props =>
      props.disabled || props.readOnly
        ? 'none'
        : '0 3px 5px rgba(95, 99, 104, 0.05), 0 10px 10px rgba(95, 99, 104, 0.05), 0 1px 18px rgba(95, 99, 104, 0.05)'};
  }

  fieldset[disabled] &,
  fieldset:disabled & {
    box-shadow: none;
  }

  border-radius: 8px;
  overflow: hidden;
  background: ${props => {
    if (props.disabled) return 'var(--color-white)'
    if (props.readOnly) return 'var(--color-grey2-G-2)'
    return 'var(--color-white)'
  }};
  border: 1px solid var(--color-sw-10);
  box-sizing: border-box;
  height: ${props => (props.$size === 'M' ? '40px' : '48px')};

  ${Block} {
    padding: ${props => (props.$size === 'M' ? '0' : '0 15px')};
  }

  ${Label} {
    top: ${props => (props.$size === 'M' ? '11px' : '14px')};
    left: ${props => (props.$size === 'M' ? '11px' : '15px')};
  }
`
