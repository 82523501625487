import { useTranslation } from 'react-i18next'
import {
  ADMINISTRATION_ADD_IMAGES,
  BUY_WINE_WINES,
  REVIEWS_MY_REVIEWS,
} from 'utils/consts/path'
import useUserRole from 'utils/user/useUserRole'

interface IProps {
  pathname: string
  id: string | null
  name: string | null
  slug: string | null
  geoId: string | null
  offerId: string | null
}
interface IBreadcrumb {
  name: string | null
  link?: string
}

export const useBredcrumbsItems: (props: IProps) => IBreadcrumb[] = ({
  pathname,
  id = null,
  name = null,
  slug = null,
  geoId = null,
  offerId = null,
}) => {
  const { t } = useTranslation()
  const { isManager, isSuperAdmin, isContent } = useUserRole()

  switch (pathname) {
    case '/acc/my-company':
      return [
        {
          name: t('common.menu.myCompany'),
        },
      ]

    case '/acc/buy-wine/analysis':
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.analysis.title'),
        },
      ]
    case BUY_WINE_WINES:
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.wines.title'),
        },
      ]
    case `/acc/buy-wine/analysis/${slug}/${geoId}/${offerId}`:
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.analysis.title'),
          link: '/acc/buy-wine/analysis',
        },
        {
          name,
        },
      ]
    case `/acc/buy-wine/my-requests/${id}/edit`:
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.myRequests.title'),
          link: '/acc/buy-wine/my-requests',
        },
        {
          name: t('buyWine.myRequests.edit'),
        },
      ]
    case `/acc/buy-wine/my-requests/${id}`:
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.myRequests.title'),
          link: '/acc/buy-wine/my-requests',
        },
        {
          name: id,
        },
      ]
    case `/acc/buy-wine/new-request`:
    case `/acc/buy-wine/new-request/check`:
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.myRequests.title'),
          link: '/acc/buy-wine/my-requests',
        },
        {
          name: t('buyWine.offers.add'),
        },
      ]

    case `/acc/buy-wine/new-deal`:
      return [
        {
          name: t('buyWine.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.offers.title'),
          link: '/acc/buy-wine/offers/all',
        },
        {
          name: t('buyWine.offers.addDeal'),
        },
      ]

    case '/acc/sell-wine/my-warehouses':
      return [
        {
          name: t('sellWine.title'),
          link: '/acc/sell-wine/my-offers/all',
        },
        {
          name: t('sellWine.myWarehouses.title'),
        },
      ]
    case `/acc/offer/${id}/edit`:
      return isManager || isSuperAdmin || isContent
        ? [
            {
              name: t('administration.title'),
              link: '/acc/administration',
            },
            {
              name: t('administration.offers.title'),
              link: '/acc/administration/offers',
            },
            {
              name: t('sellWine.myOffers.edit'),
            },
          ]
        : [
            {
              name: t('sellWine.title'),
              link: '/acc/sell-wine/my-offers/all',
            },
            {
              name: t('sellWine.myOffers.title'),
              link: '/acc/sell-wine/my-offers/all',
            },
            {
              name: t('sellWine.myOffers.edit'),
            },
          ]
    case '/acc/offer/create/wine':
    case '/acc/offer/create/sale-terms':
    case '/acc/offer/create/check':
      return isManager || isSuperAdmin
        ? [
            {
              name: t('administration.title'),
              link: '/acc/administration',
            },
            {
              name: t('administration.offers.title'),
              link: '/acc/administration/offers',
            },
            {
              name: t('offer.create.breadcrumb'),
            },
          ]
        : [
            {
              name: t('sellWine.title'),
              link: '/acc/sell-wine/my-offers/all',
            },
            {
              name: t('sellWine.myOffers.title'),
              link: '/acc/sell-wine/my-offers/all',
            },
            {
              name: t('offer.create.breadcrumb'),
            },
          ]

    case '/acc/company/create/step-1':
    case '/acc/company/create/step-2':
      return [
        {
          name: t('myCompany.title'),
        },
        {
          name: t('company.create.addCompany'),
        },
      ]
    case `/acc/company/${id}`:
      return [
        {
          name: t('myCompany.title'),
          link: '/acc/my-company',
        },
        {
          name,
        },
      ]
    case `/acc/company/${id}/edit`:
      return [
        {
          name: t('myCompany.title'),
          link: '/acc/my-company',
        },
        {
          name,
          link: `/acc/company/${id}`,
        },
        {
          name: t('common.edit'),
        },
      ]

    case `/acc/user`:
    case `/acc/user/${id}/profile`:
    case `/acc/user/${id}/settings`:
    case `/acc/user/${id}/companies`:
      return [
        {
          name: t('user.title'),
        },
      ]

    case `/acc/database/producers`:
    case `/acc/database/producers/all`:
    case `/acc/database/producers/duplicates`:
    case `/acc/database/producers/duplicatesOf/${id}`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database/wines',
        },
        {
          name: t('database.producers.title'),
        },
      ]
    case `/acc/database/awards`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database/wines',
        },
        {
          name: t('database.awards.title'),
        },
      ]
    case `/acc/database/brands`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database/wines',
        },
        {
          name: t('database.brands.title'),
        },
      ]
    case `/acc/database/wines`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database/wines',
        },
        {
          name: t('database.wines.title'),
        },
      ]
    case `/acc/database/geo-indication`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database/wines',
        },
        {
          name: t('database.geoIndication.title'),
        },
      ]
    case `/acc/database/exhibitions`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database/wines',
        },
        {
          name: t('database.exhibitions.title'),
        },
      ]

    case `/acc/review/create`:
      return [
        {
          name: t('myReviews.title'),
          link: REVIEWS_MY_REVIEWS + '/all',
        },
        {
          name: t('review.create.title'),
        },
      ]

    case `/acc/review/${id}/edit`:
      return [
        {
          name: t('myReviews.title'),
          link: REVIEWS_MY_REVIEWS + '/all',
        },
        {
          name: id,
        },
        {
          name: t('common.edit'),
        },
      ]

    case `/acc/reviews/company-reviews`:
      return [
        {
          name: t('common.menu.reviews'),
        },
        {
          name: t('companyReviews.title'),
        },
      ]
    case `/acc/reviews/company-reviews/request-buy-review`:
      return [
        {
          name: t('common.menu.reviews'),
        },
        {
          name: t('companyReviews.title'),
        },
        {
          name: t('buyReview.title'),
        },
      ]
    case `/acc/exhibition/create`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database',
        },
        {
          name: t('exhibition.create.breadcrumbs.list'),
          link: '/acc/database/exhibitions',
        },
        {
          name: t('exhibition.create.breadcrumbs.create'),
        },
      ]
    case `/acc/exhibition/${id}/edit`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database',
        },
        {
          name: t('exhibition.create.breadcrumbs.list'),
          link: '/acc/database/exhibitions',
        },
        {
          name: t('exhibition.edit.breadcrumbs'),
        },
      ]
    case `/acc/geo-indication/create`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database',
        },
        {
          name: t('geoIndication.create.breadcrumbs.geoIndication'),
          link: '/acc/database/geo-indication',
        },
        {
          name: t('geoIndication.create.breadcrumbs.create'),
        },
      ]
    case `/acc/geo-indication/${id}/edit`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database',
        },
        {
          name: t('geoIndication.create.breadcrumbs.geoIndication'),
          link: '/acc/database/geo-indication',
        },
        {
          name: t('geoIndication.create.breadcrumbs.create'),
        },
      ]
    case `/acc/award/create`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database',
        },
        {
          name: t('award.breadcrumbs.list'),
          link: '/acc/database/awards',
        },
        {
          name: t('award.breadcrumbs.create'),
        },
      ]
    case `/acc/award/${id}/edit`:
      return [
        {
          name: t('database.title'),
          link: '/acc/database',
        },
        {
          name: t('award.breadcrumbs.list'),
          link: '/acc/database/awards',
        },
        {
          name: t('award.breadcrumbs.edit'),
        },
      ]
    case `/acc/elabel/${id}/edit/general-info`:
    case `/acc/elabel/${id}/edit/ingredients`:
    case `/acc/elabel/${id}/edit/nutrition-declaration`:
    case `/acc/elabel/${id}/edit/certificates`:
    case `/acc/elabel/${id}/edit/responsible-consumption`:
    case `/acc/elabel/${id}/edit/packaging`:
      return [
        {
          name: t('myWines.title'),
          link: '/acc/my-wines/wines/all',
        },
        {
          name: t('myWines.subMenu.eLabels'),
          link: '/acc/my-wines/e-labels',
        },
        {
          name: id,
        },
        {
          name: t('elabel.edit.breadcrumb'),
        },
      ]
    case ADMINISTRATION_ADD_IMAGES:
      return [
        {
          name: t('administration.title'),
          link: '/acc/administration',
        },
        {
          name: t('administration.images.title'),
          link: '/acc/administration/images',
        },
        {
          name: t('image.create.title'),
        },
      ]

    case `/acc/administration/images/wine/${id}`:
      return [
        {
          name: t('administration.title'),
          link: '/acc/administration',
        },
        {
          name: t('administration.images.title'),
          link: '/acc/administration/images',
        },
        {
          name: t('wineImage.title', { n: id }),
        },
      ]
    case `/acc/administration/requests/new-request`:
    case `/acc/administration/requests/new-request/check`:
      return [
        {
          name: t('administration.title'),
          link: '/acc/administration',
        },
        {
          name: t('administration.requests.title'),
          link: '/acc/administration/requests',
        },
        {
          name: t('buyWine.myRequests.addNewRequest.header'),
        },
      ]
    case `/acc/administration/request/${id}`:
      return [
        {
          name: t('administration.title'),
          link: '/acc/administration',
        },
        {
          name: t('administration.requests.title'),
          link: '/acc/administration/requests',
        },
        {
          name: id,
        },
      ]
    case `/acc/administration/mentions/create`:
      return [
        {
          name: t('administration.title'),
          link: '/acc/administration',
        },
        {
          name: t('administration.mentions.title'),
          link: '/acc/administration/mentions',
        },
        {
          name: t('administration.mentions.addNewItem'),
        },
      ]
    case `/acc/retail/e-shops`:
      return [
        {
          name: t('retail.title'),
          link: '/acc/retail/e-shops',
        },
        {
          name: t('retail.eshops.title'),
          link: '/acc/retail/e-shops',
        },
      ]
    case `/acc/retail/buyers-wine`:
      return [
        {
          name: t('retail.title'),
          link: '/acc/retail/buyers-wine',
        },
        {
          name: t('retail.buyersWine.title'),
          link: '/acc/retail/buyers-wine',
        },
      ]
    case `/acc/retail/geo-indications`:
      return [
        {
          name: t('retail.title'),
          link: '/acc/retail/geo-indications',
        },
        {
          name: t('retail.geoIndications.title'),
          link: '/acc/retail/geo-indications',
        },
      ]
    case `/acc/retail/wines/all`:
    case `/acc/retail/wines/my-range`:
    case `/acc/retail/wines/compare`:
      return [
        {
          name: t('retail.title'),
          link: '/acc/retail/wines',
        },
        {
          name: t('retail.wines.title'),
          link: '/acc/retail/wines',
        },
      ]

    default:
      return [{ name: name ?? '' }]
  }
}
