import { ls } from 'utils/my-localstorage'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import {
  getSelectedCompany,
  getSelectedCompanyTour,
  getUserData,
} from 'store/me'
import {
  ADMIN_PATH,
  ADMINISTRATION_MERCHANTS,
  ADMINISTRATION_TRADE_OFFERS,
  ADMINISTRATION_MENTIONS,
  BUY_WINE_PATH,
  DATABASE_PATH_PREFIX,
  GETTING_STARTED_PATH,
  HOMEPAGE_PATH,
  MY_COMPANY_PATH,
  MY_WINES_PATH,
  REVIEWS_PATH,
  SELL_WINE_PATH,
  RETAIL,
} from 'utils/consts/path'
import PersonCategories, {
  ManagementRoles,
  Roles,
} from 'utils/consts/userRoles'
import { useCheckAccessToDatabase } from 'utils/routes/utils'
import {
  Account,
  Administration,
  BuyWine,
  Database,
  GettingStarted,
  Homepage,
  MyCompany,
  MyWines,
  Reviews,
  SellWine,
  Retail,
} from './menu-icons'

export type MenuItem = {
  id: string
  title: string
  icon: FC
  link: string
  forVerifiedOnly?: boolean
  badge?: (state: any) => boolean | number
  items?: Array<{
    id: string
    title: string
    link: string
    forVerifiedOnly?: boolean
    badge?: (state: any) => boolean | number
    isPro?: boolean
  }>
}

export const menuItems: Record<string, MenuItem> = {
  homepage: {
    id: 'homepage',
    icon: Homepage,
    title: 'common.menu.homePage',
    link: HOMEPAGE_PATH,
  },
  gettingStarted: {
    id: 'getting-started',
    icon: GettingStarted,
    title: 'common.menu.gettingStarted',
    link: GETTING_STARTED_PATH,
  },
  myWines: {
    id: 'my-wines',
    icon: MyWines,
    title: 'common.menu.myWines',
    link: MY_WINES_PATH,
    items: [
      {
        id: 'my-wines-wines',
        title: 'common.menu.myWinesWines',
        link: MY_WINES_PATH + '/wines/all',
      },
      {
        id: 'my-wines-brands',
        title: 'common.menu.myWinesBrands',
        link: MY_WINES_PATH + '/brands',
      },
      {
        id: 'my-wines-e-labels',
        title: 'common.menu.myWinesLabels',
        link: MY_WINES_PATH + '/e-labels',
        forVerifiedOnly: true,
      },
    ],
  },
  sellWine: {
    id: 'sell-wine',
    icon: SellWine,
    title: 'common.menu.sellWine',
    link: SELL_WINE_PATH,
    forVerifiedOnly: true,
    badge: state =>
      state?.negotiations?.newAnswers?.seller?.buyerConditionsCount > 0 ||
      state?.requests?.notCompletedCount > 0,
    items: [
      {
        id: 'sell-wine-offers',
        title: 'common.menu.sellWineOffers',
        link: SELL_WINE_PATH + '/my-offers',
        badge: state =>
          state?.negotiations?.newAnswers?.seller?.buyerConditionsCount,
      },
      {
        id: 'sell-wine-requests',
        title: 'common.menu.sellWineRequests',
        link: SELL_WINE_PATH + '/requests',
        badge: state => state?.requests?.notCompletedCount,
      },
      {
        id: 'sell-wine-my-warehouses',
        title: 'common.menu.sellWineMyWarehouses',
        link: SELL_WINE_PATH + '/my-warehouses',
      },
    ],
  },
  buyWine: {
    id: 'buy-wine',
    icon: BuyWine,
    title: 'common.menu.buyWine',
    link: BUY_WINE_PATH,
    forVerifiedOnly: true,
    badge: state =>
      state?.negotiations?.newAnswers?.buyer?.sellerConditionsCount > 0,
    items: [
      {
        id: 'buy-wine-offers',
        title: 'common.menu.buyWineOffers',
        link: BUY_WINE_PATH + '/offers',
        badge: state =>
          state?.negotiations?.newAnswers?.buyer?.sellerConditionsCount,
      },
      {
        id: 'buy-wine-requests',
        title: 'common.menu.buyWineRequests',
        link: BUY_WINE_PATH + '/my-requests',
      },
      {
        id: 'buy-wine-analysis',
        title: 'common.menu.buyWineAnalysis',
        link: BUY_WINE_PATH + '/analysis',
      },
      {
        id: 'buy-wine-wines',
        title: 'common.menu.buyWineWines',
        link: BUY_WINE_PATH + '/wines',
      },
    ],
  },
  retail: {
    id: 'retail',
    icon: Retail,
    title: 'common.menu.retail',
    link: RETAIL,
    forVerifiedOnly: true,
    items: [
      {
        id: 'retail-e-shops',
        title: 'common.menu.retailEShops',
        link: RETAIL + '/e-shops',
      },
      {
        id: 'retail-geo-indications',
        title: 'common.menu.retailGeoIndications',
        link: RETAIL + '/geo-indications',
        isPro: true,
      },
      {
        id: 'retail-wines',
        title: 'common.menu.retailWines',
        link: RETAIL + '/wines/all',
        isPro: true,
      },
    ],
  },
  administrationRetail: {
    id: 'retail',
    icon: Retail,
    title: 'common.menu.retail',
    link: RETAIL,
    items: [
      {
        id: 'retail-e-shops',
        title: 'common.menu.retailEShops',
        link: RETAIL + '/e-shops',
      },
      {
        id: 'retail-geo-indications',
        title: 'common.menu.retailGeoIndications',
        link: RETAIL + '/geo-indications',
      },
      {
        id: 'retail-wines',
        title: 'common.menu.retailWines',
        link: RETAIL + '/wines/all',
      },
      {
        id: 'retail-buyers-wine',
        title: 'common.menu.retailBuyersWine',
        link: RETAIL + '/buyers-wine',
      },
    ],
  },
  myCompany: {
    id: 'my-company',
    icon: MyCompany,
    title: 'common.menu.myCompany',
    link: MY_COMPANY_PATH,
  },

  databasePerson: {
    id: 'database',
    icon: Database,
    title: 'common.menu.database',
    link: DATABASE_PATH_PREFIX,
    items: [
      {
        id: 'database-producers',
        title: 'common.menu.databaseProducers',
        link: DATABASE_PATH_PREFIX + '/producers',
      },
      {
        id: 'database-brands',
        title: 'common.menu.databaseBrands',
        link: DATABASE_PATH_PREFIX + '/brands',
      },
      {
        id: 'wines',
        title: 'common.menu.databaseWines',
        link: DATABASE_PATH_PREFIX + '/wines',
      },
    ],
  },

  databaseManage: {
    id: 'database',
    icon: Database,
    title: 'common.menu.database',
    link: DATABASE_PATH_PREFIX,
    items: [
      {
        id: 'database-producers',
        title: 'common.menu.databaseProducers',
        link: DATABASE_PATH_PREFIX + '/producers',
      },
      {
        id: 'database-brands',
        title: 'common.menu.databaseBrands',
        link: DATABASE_PATH_PREFIX + '/brands',
      },
      {
        id: 'wines',
        title: 'common.menu.databaseWines',
        link: DATABASE_PATH_PREFIX + '/wines',
      },
      {
        id: 'exhibitions',
        title: 'common.menu.databaseExhibitions',
        link: DATABASE_PATH_PREFIX + '/exhibitions',
      },
      {
        id: 'awards',
        title: 'common.menu.databaseAwards',
        link: DATABASE_PATH_PREFIX + '/awards',
      },
      {
        id: 'geo-indication',
        title: 'common.menu.databaseGeoIndication',
        link: DATABASE_PATH_PREFIX + '/geo-indication',
      },
    ],
  },

  reviewsPerson: {
    id: 'reviews',
    icon: Reviews,
    title: 'common.menu.reviews',
    link: REVIEWS_PATH,
    items: [
      {
        id: 'reviews-dashboard',
        title: 'common.menu.reviewsDashboard',
        link: REVIEWS_PATH + '/dashboard',
      },
      {
        id: 'reviews-my-reviews',
        title: 'common.menu.reviewsMyReviews',
        link: REVIEWS_PATH + '/my-reviews',
      },
    ],
  },

  reviewsCompany: {
    id: 'reviews',
    icon: Reviews,
    title: 'common.menu.reviews',
    link: REVIEWS_PATH,
    items: [
      {
        id: 'reviews-dashboard',
        title: 'common.menu.reviewsDashboard',
        link: REVIEWS_PATH + '/dashboard',
      },
      {
        id: 'reviews-my-reviews',
        title: 'common.menu.reviewsMyReviews',
        link: REVIEWS_PATH + '/my-reviews',
      },
      {
        id: 'reviews-company',
        title: 'common.menu.reviewsCompanyReviews',
        link: REVIEWS_PATH + '/company-reviews',
        forVerifiedOnly: true,
      },
    ],
  },

  administrationAdmin: {
    id: 'administration',
    icon: Administration,
    title: 'common.menu.administration',
    link: ADMIN_PATH,
    // badge: state => state?.administration?.reviewPendings > 0,
    items: [
      {
        id: 'administration-users',
        title: 'administration.users.title',
        link: ADMIN_PATH + '/users',
      },
    ],
  },
  administrationManager: {
    id: 'administration',
    icon: Administration,
    title: 'common.menu.administration',
    link: ADMIN_PATH,
    items: [
      {
        id: 'administration-companies',
        title: 'administration.companies.title',
        link: ADMIN_PATH + '/companies',
      },
      {
        id: 'administration-reviews',
        title: 'administration.reviews.title',
        link: ADMIN_PATH + '/reviews',
      },
      {
        id: 'administration-offers',
        title: 'administration.offers.title',
        link: ADMIN_PATH + '/offers',
      },
      {
        id: 'administration-requests',
        title: 'administration.requests.title',
        link: ADMIN_PATH + '/requests',
      },
      {
        id: 'administration-images',
        title: 'administration.images.title',
        link: ADMIN_PATH + '/images',
      },
      {
        id: 'administration-e-labels',
        title: 'administration.elabels.title',
        link: ADMIN_PATH + '/e-labels',
      },
      {
        id: 'administration-retail-offers',
        title: 'administration.tradeOffers.title',
        link: ADMINISTRATION_TRADE_OFFERS + '/all',
      },
      {
        id: 'administration-e-shops',
        title: 'administration.merchants.title',
        link: ADMINISTRATION_MERCHANTS,
      },
    ],
  },

  administrationManage: {
    id: 'administration',
    icon: Administration,
    title: 'common.menu.administration',
    link: ADMIN_PATH,
    // badge: state => state?.administration?.reviewPendings > 0,
    items: [
      {
        id: 'administration-users',
        title: 'administration.users.title',
        link: ADMIN_PATH + '/users',
      },
      {
        id: 'administration-companies',
        title: 'administration.companies.title',
        link: ADMIN_PATH + '/companies',
      },
      {
        id: 'administration-reviews',
        title: 'administration.reviews.title',
        link: ADMIN_PATH + '/reviews',
        // badge: state => state?.administration?.reviewPendings,
      },
      {
        id: 'administration-offers',
        title: 'administration.offers.title',
        link: ADMIN_PATH + '/offers',
      },
      {
        id: 'administration-requests',
        title: 'administration.requests.title',
        link: ADMIN_PATH + '/requests',
      },
      {
        id: 'administration-images',
        title: 'administration.images.title',
        link: ADMIN_PATH + '/images',
      },
      {
        id: 'administration-e-labels',
        title: 'administration.elabels.title',
        link: ADMIN_PATH + '/e-labels',
      },
      {
        id: 'administration-retail-offers',
        title: 'administration.tradeOffers.title',
        link: ADMINISTRATION_TRADE_OFFERS + '/all',
      },
      {
        id: 'administration-price-sources',
        title: 'administration.priceSources.title',
        link: ADMIN_PATH + '/price-sources',
      },
      {
        id: 'administration-e-shops',
        title: 'administration.merchants.title',
        link: ADMINISTRATION_MERCHANTS,
      },
      {
        id: 'administration-mentions',
        title: 'administration.mentions.title',
        link: ADMINISTRATION_MENTIONS,
      },
    ],
  },

  account: {
    id: 'account',
    icon: Account,
    title: 'common.menu.account',
    link: '/acc/user',
    items: [],
  },
}

export function useMenuItems(): MenuItem[] {
  const data = useSelector(getUserData)
  const company = useSelector(getSelectedCompany)
  const role = data?.role?.code || ls.getItem('wwl-role_code') || Roles.PERSON
  const hasAccessToDatabase = useCheckAccessToDatabase()

  const tour = useSelector(getSelectedCompanyTour)
  const showGettingStarted = tour && !tour.completed && !tour.skipped

  let items: any[]

  if (role === ManagementRoles.ADMIN) {
    items = [menuItems.administrationAdmin]
  }
  // ?? menuItems.databaseAdmin (producers, brands, wines, exhibitions)
  else if (role === ManagementRoles.SUPERADMIN) {
    items = [
      menuItems.homepage,
      menuItems.administrationManage,
      menuItems.administrationRetail,
      menuItems.databaseManage,
    ]
  } else if (role === ManagementRoles.CONTENT) {
    items = [
      menuItems.administrationManage,
      menuItems.administrationRetail,
      menuItems.databaseManage,
    ]
  } else if (role === ManagementRoles.MANAGER) {
    items = [
      menuItems.administrationManager,
      menuItems.administrationRetail,
      menuItems.databasePerson,
    ]
  } else {
    const category =
      company?.companyCategory?.masterName ||
      ls.getItem('wwl-person_category') ||
      PersonCategories.PERSON

    if (category === PersonCategories.PRODUCER) {
      items = [
        showGettingStarted ? menuItems.gettingStarted : menuItems.homepage,
        menuItems.myWines,
        menuItems.sellWine,
        menuItems.retail,
        menuItems.myCompany,
        menuItems.reviewsCompany,
      ]
    } else if (category === PersonCategories.WHOLESALER) {
      items = [
        showGettingStarted ? menuItems.gettingStarted : menuItems.homepage,
        menuItems.sellWine,
        menuItems.buyWine,
        menuItems.retail,
        menuItems.myCompany,
        menuItems.reviewsCompany,
      ]
    } else if (category === PersonCategories.RETAILER) {
      items = [
        showGettingStarted ? menuItems.gettingStarted : menuItems.homepage,
        menuItems.sellWine,
        menuItems.buyWine,
        menuItems.retail,
        menuItems.myCompany,
        menuItems.reviewsCompany,
      ]
    } else if (category === PersonCategories.RESTAURANT) {
      items = [
        showGettingStarted ? menuItems.gettingStarted : menuItems.homepage,
        menuItems.sellWine,
        menuItems.buyWine,
        menuItems.retail,
        menuItems.myCompany,
        menuItems.reviewsCompany,
      ]
    } else {
      items = [
        showGettingStarted ? menuItems.gettingStarted : menuItems.homepage,
        menuItems.reviewsPerson,
      ]
    }
  }
  return hasAccessToDatabase
    ? items.filter(Boolean)
    : items.filter(route => {
        return route && !route.link.startsWith(DATABASE_PATH_PREFIX)
      })
}
