import { useDispatch } from 'react-redux'
import { useGetCompanyLazyQuery, useGetMeQuery } from 'api/hooks'
import { getSelectedCompanyId } from 'utils/company'
import { ls } from 'utils/my-localstorage'
import { setData, setSelectedCompanyId } from 'store/me'
import PersonCategories, { Roles } from 'utils/consts/userRoles'
import { getLocaleId } from 'utils/getLocaleId'
import { isLogin } from 'utils/token'
import { captureException } from 'utils/sentry'

export const initLocalStorage = (data: any) => {
  if (!data) return {}

  const role = data?.role?.code || Roles.PERSON
  ls.setItem('wwl-role_code', role)

  const selectedCompanyId = getSelectedCompanyId()
  const selectedCompany =
    data?.companies?.find((company: any) => {
      return company?.id === selectedCompanyId
    }) ?? data?.companies?.[0]

  const strSelectedCompanyId = selectedCompany?.id
    ? String(selectedCompany.id)
    : '0'
  if (selectedCompanyId !== strSelectedCompanyId) {
    ls.setItem('wwl-selected_company_id', strSelectedCompanyId)
  }

  ls.setItem(
    'wwl-person_category',
    selectedCompany?.companyCategory?.masterName ?? PersonCategories.PERSON
  )

  const isVerified = selectedCompany?.isVerifiedUser ?? false
  ls.setItem('wwl-verified_company', isVerified.toString())

  return { role, selectedCompany, isVerified }
}

export const useLoadUserData = () => {
  const dispatch = useDispatch()

  const isGuest = !isLogin()
  const [getCurrentCompanyTour] = useGetCompanyLazyQuery()
  const getTour = async companyId => {
    try {
      const { data } = await getCurrentCompanyTour({
        variables: { companyId },
      })
      return data?.me?.company?.tour
    } catch {
      return
    }
  }
  useGetMeQuery({
    variables: { localeId: getLocaleId() },
    fetchPolicy: 'network-only',
    context: { ignoreAccessErrors: isGuest },
    skip: isGuest,
    notifyOnNetworkStatusChange: true,
    onCompleted: async data => {
      if (!data?.me) return

      const { selectedCompany } = initLocalStorage(data?.me)
      if (selectedCompany?.id) {
        const companyTour = await getTour(selectedCompany?.id)
        const companies = (data.me.companies ?? []).map(company => {
          if (company.id === selectedCompany?.id)
            return { ...company, tour: companyTour }
          return company
        })
        dispatch(setData({ ...data.me, companies }))
      } else {
        dispatch(setData(data.me))
      }

      dispatch(setSelectedCompanyId(selectedCompany?.id))

      // Передача данных пользователя в Яндекс.Метрику
      if (process.env.NODE_ENV === 'production') {
        try {
          const companyCategory =
            selectedCompany?.companyCategory?.masterName ?? ''

          // @ts-ignore
          // eslint-disable-next-line no-undef
          ym(86875022, 'userParams', {
            username: data?.me?.username ?? '',
            companyCategory,
          })
        } catch {
          captureException('ym is not available')
        }
      }
    },
  })
}
